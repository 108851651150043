.app-footer {
    display: flex;
    flex-direction: column;
    background: var(--app-footer-background);
    padding: 10px 20px 20px 20px;
}

.app-footer ul {
    list-style-type: none;
    font-size: 0.8em;
    padding: 10px;
    color: var(--app-footer-text);
}

.app-footer strong, .app-footer p {
    display: inline;
}

.app-footer > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.app-footer > div >:not(:first-child) {
    margin-left: 20px;
}

.app-footer img {
    max-height: 35px;
    max-width: 120px;
    width: auto;
    height: auto;
}