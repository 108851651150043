:root {
    --app-font-family-brand: 'unit', 'Roboto', sans-serif;

    --app-font-settings-brand: "slnt" -9, "wdth" 60, "wght" 800;
    --app-font-settings-header: "slnt" 0, "wdth" 90, "wght" 700;
    --app-font-settings-base: "slnt" 0, "wdth" 100, "wght" 300;
    --app-font-settings-link: "slnt" -9, "wdth" 70, "wght" 500;
    --app-font-settings-link-helpdesk: "slnt" 0, "wdth" 100, "wght" 500;

    --app-color-brand: #EF0E39;
    --app-color-brand-highlight: #E53535;
    --app-color-brand-dark: #292929;
    --app-color-brand-darker: #1A1C21;
    --app-color-brand-light: #cdcbcb;
    --app-color-brand-neutral: #85858A;

    --app-color-brand-accent: #3fb950;

    --app-color-white: #fff;
    --app-color-black: #000;

    --app-link-color: #FFC659;

    --app-background: linear-gradient(177deg, var(--app-color-brand-dark), var(--app-color-brand-darker), var(--app-color-brand-darker), var(--app-color-brand-darker));

    --app-header-height: 56px;
    --app-header-background: var(--app-color-brand-dark);
    --app-header-color: var(--app-color-white);

    --app-color-neutral-white: hsla(240, 2%, 53%, 0.3);
    --app-color-neutral: #fff;
    --app-color-neutral-darker: var(--app-color-neutral);

    --app-color-shadow-line: hsla(189, 9%, 15%, 0.15);
    --app-shadow-low: 0 0 1px #cdcbcb;
    --app-shadow-mid: 0 2px 4px var(--app-color-shadow-line);
}

body {
    background: var(--app-background);
    font-family: var(--app-font-family-brand);
    font-variation-settings: var(--app-font-settings-base);
}

h1 {
    font-variation-settings: var(--app-font-settings-brand);
}

h2, h3 {
    font-variation-settings: var(--app-font-settings-header);
}

body a {
    color: var(--app-link-color);
    font-family: var(--app-font-family-brand);
    font-variation-settings: var(--app-font-settings-link);
    margin: .3em 0 0;
}
header {
    color: var(--app-header-color);
}
header button {
    color: var(--app-header-color);
}

.app-content__column {
    border-image-source: linear-gradient(45deg, var(--app-color-brand), var(--app-color-brand-highlight));
    border-image-slice: 1 1 0 0;
}
.app-logo::after {
    content: url('../svgs/logo-eleven.svg');
    width: 4em;
    margin-top: .5em;
}
.app-contact-details__content {
    border-radius: 0 0 .7em .7em;
}
.app-contact-details-list__info {
    margin-bottom: .3em;
    font-variation-settings: var(--app-font-settings-link-helpdesk);
}
.app-release-status__title {
    font-weight: 700 !important;
}
.app-release-status__subtitle {
    padding-top: .5em;
}
.app-release-status__time {
    font-size: 100em;
    font-variation-settings: var(--app-font-settings-brand);
}
